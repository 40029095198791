@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Poppins", sans-serif;
  padding: 0px;
  margin: 0px;
  line-height: 1;
}

html {
  height: 100vh;
  width: 100vw;
}

:root {
  --primary: #ffa800;
  --dark: #1b1a18;
  --gray: #9e9e9d;
  --success: #07a434;
  --error: #f03535;
  --link: #1e55e2;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
