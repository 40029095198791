#card {
  background-color: #fff;
  box-shadow: 6.75273px 5.94984px 11px rgba(0, 0, 0, 0.06),
    1.35055px 1.18997px 1.7875px rgba(0, 0, 0, 0.03);
  height: max-content;
  width: max-content;
  flex: 1;
  max-width: 500px;
}

#card .button-card {
  height: 229px;
  width: 350px;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #d1d1d1;
}

#card .icon {
  height: 46px;
  width: 46px;
  margin-top: 44px;
  margin-bottom: 47.5px;
}

#card .top-text {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 10px;
  width: 100%;
  word-break: break-all;
  text-align: center;
}

#card .bottom-text {
  margin-bottom: 11px;
  font-size: 12px;
  font-weight: 400px;
  text-align: center;
}

#input {
  height: max-content;
  width: 100%;
  position: relative;
  margin-bottom: 23.5px;
}

#input .input-wrapper {
  margin-bottom: 10.5px;
}

#input .under-input {
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#input .under-input .error-message {
  font-size: 12px;
  letter-spacing: 0.1px;
  font-weight: 400;
  color: #f03535;
}

#input .under-input .letter-counter {
  font-size: 12px;
  font-weight: 400px;
  letter-spacing: 0.1px;
  color: #1b1a17;
  opacity: 0.6;
}

#input .input-wrapper {
  background-color: transparent;
  padding: 40px 25.5px 16px 25.5px;
}

#input .input-wrapper.error {
  border: 1px solid #f03535;
}
#input .input-wrapper.focused {
  border: 1px solid #1b1a17;
}
#input .input-wrapper.default {
  border: 1px solid #bcbcbc;
}

.content .burn {
  max-width: 280px;
  width: 100%;
  transition: transform 0.1s ease;
}

.content .burn:hover {
  transform: scale(1.005);
}

#input label {
  position: absolute;
  top: 18px;
  left: 25.5px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: #727270;
}

#input input,
#input textarea {
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: 400;
  resize: none;
  width: 100%;
  box-sizing: border-box;
}

#input input.title {
  font-size: 24px;
}

#input input.url {
  font-size: 16px;
  color: #1e55e2;
  letter-spacing: 0.2px;
}

#input textarea::-webkit-scrollbar {
  appearance: none;
}

#input textarea.description {
  font-size: 16px;
  line-height: 1.5;
  padding: 0px;
}

#input .default.description {
  padding: 0px;
}

#header {
  width: 100%;
  height: 100%;
  padding: 0px 96px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#footer {
  height: 100%;
  width: 100%;
  padding: 0px 39px;
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#footer .social-media-wrapper {
  display: flex;
  flex-direction: row;
}

#social-media-link {
  margin-right: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  font-size: 10px;
  letter-spacing: 0.04em;
  font-weight: 400;
  color: #7b7b7b;
}

#social-media-link img {
  margin-right: 8px;
  height: 18px;
  width: 18px;
}

#progress {
  height: max-content;
  width: max-content;
  min-height: 115px;
  margin-bottom: 80px;
}

#progress .create-new-text {
  font-size: 14px;
  letter-spacing: 0.1px;
  font-weight: 600;
  color: #1b1a17;
}

#progress .numbers-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 20px;
}

#progress .numbers-bar span {
  font-size: 16px;
  letter-spacing: 0.02em;
  font-weight: 600;
  color: #8f8f8e;
}
#progress .numbers-bar span.active {
  color: #1b1a17;
}

#progress .progress-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  filter: drop-shadow(0px 58px 80px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 37.5926px 46.8519px rgba(0, 0, 0, 0.037963))
    drop-shadow(0px 22.3407px 25.4815px rgba(0, 0, 0, 0.0303704))
    drop-shadow(0px 11.6px 13px rgba(0, 0, 0, 0.025))
    drop-shadow(0px 4.72593px 6.51852px rgba(0, 0, 0, 0.0196296))
    drop-shadow(0px 1.07407px 3.14815px rgba(0, 0, 0, 0.012037));
}

#progress .progress-bar .dot {
  height: 12px;
  width: 12px;
  background-color: #ffffff;
}

#progress .progress-bar .dot-1.color {
  background-color: #bb5dee;
}

#progress .progress-bar .dot-2.color {
  background-color: #6747ec;
}

#progress .progress-bar .dot-3.color {
  background-color: #3494fe;
}

#progress .progress-bar .line {
  width: calc(50% - 16px);
  height: 5px;
  background-color: #ffffff;
}

#progress .progress-bar .line-1.color {
  background: linear-gradient(90deg, #bb5dee, #6947ec);
}

#progress .progress-bar .line-2.color {
  background: linear-gradient(90deg, #6546ec, #3691ff);
}

#progress .text-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

#progress .text-bar span {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #8f8f8e;
}

#progress .text-bar span.active {
  color: #1b1a17;
}

#search-bar {
  position: relative;
  width: 547px;
  margin-right: 30px;
}

#search-bar input {
  padding: 16px 53px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #bcbcbc;
  width: 100%;
  box-sizing: border-box;
}

#search-bar input::placeholder {
  font-size: 16px;
  font-weight: 400;
}

#search-bar input:focus {
  outline: none;
  border: 1px solid #1b1a17;
}

#search-bar .magnifier {
  position: absolute;
  top: 17px;
  left: 16px;
}

#qr-with-token-name {
  max-width: 100%;
  height: max-content;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

#qr-with-token-name .qr {
  height: 96px;
  width: 96px;
  padding: 8px;
  background-color: white;
  margin-right: 30px;
}

#qr-with-token-name .token-name {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #1b1a17;
  word-break: break-all;
  width: 100%;
}

#qr-with-token-name .nft-address {
  display: none;
}

#nft-address {
  height: max-content;
  width: 100%;
  max-width: 100%;
  padding: 11px;
  background-color: white;
  margin-bottom: 63px;
  display: flex;
  flex-direction: column;
  word-break: break-all;
  box-sizing: border-box;
}

#nft-address .address {
  word-break: break-all;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.1px;
  font-weight: 600;
  color: #1b1a17;
  line-height: 1.4;
}

.nft-action-button {
  border: 1px solid #474644;
  background-color: white;
  width: 120px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
}

.nft-action-button:disabled {
  opacity: 0.4;
  cursor: auto;
}

.nft-action-button .button-text {
  font-size: 12px;
  font-weight: 500;
  color: #1b1a17;
}

.nft-action-button .button-img {
  width: 18px;
  height: auto;
}

#nft-actions {
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}

#burn-nft {
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  height: max-content;
  width: max-content;
  font-size: 12px;
  font-weight: 500;
  color: #f03535;
  cursor: pointer;
  width: 120px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

#burn-nft:disabled {
  color: #9e9e9d;
  cursor: auto;
}

/* #nft-actions > button:not(#burn-nft) {
  margin-right: 30px;
} */

/* #nft-actions #burn-nft {
  margin-left: 22.5px;
} */

#token-switcher {
  height: max-content;
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 113px;
}

#token-switcher button {
  min-width: unset;
}

#token-switcher button:disabled {
  background-color: transparent !important;
  border: 1px solid #afafae !important;
  color: #afafae !important;
  line-height: 1 !important;
}

#owner-info-wrapper {
  width: 100%;
  height: max-content;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 27px;
  border-top: 1px solid #dbdada;
}

#owner-info-wrapper .owner-info {
  height: 24px;
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;
}

#owner-info-wrapper .owner-info .avatar {
  clip-path: circle(50%);
  height: 24px;
  margin-right: 13px;
}

#owner-info-wrapper .owner-info .owner-address {
  font-size: 16px;
  letter-spacing: 0.02em;
  font-weight: 600;
  margin-right: 21px;
  color: #1b1a17;
}

.owner-side .copied {
  font-size: 14px;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: #1b1a17;
}

.owner-side .copy {
  background-color: transparent !important;
  border: none;
  height: max-content;
  width: max-content;
  cursor: pointer;
  /* background-image: url("/public/images/svg/copy-btn.svg"); */
}

.owner-side .copy:disabled {
  background-color: transparent;
  cursor: auto;
}

.owner-side .copy img {
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.owner-side .owner-text {
  margin-left: 37.5px;
  font-size: 10px;
  letter-spacing: 0.02em;
  font-weight: 400;
  color: #1b1a17;
}

#owner-info-wrapper .explorer-side a {
  text-decoration: none;
  color: #1e55e2;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.2px;
}

#owner-info-wrapper .explorer-side a.disabled {
  pointer-events: none;
  color: #1b1a17;
  opacity: 0.4;
}

#progress-step {
  max-width: 375px;
  font-size: 36px;
  letter-spacing: 0.02em;
  font-weight: 600;
  line-height: 1.2;
  color: #1b1a17;
  margin-bottom: 63px;
}

#nft-data {
  width: 100%;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 30px;
  align-self: flex-end;
  box-sizing: border-box;
}

#created-at {
  font-size: 10px;
  letter-spacing: 0.2px;
  font-weight: 600;
  color: #1b1a17;
  margin-bottom: 18.5px;
}

#connect-wallet-view {
  display: flex;
  flex-direction: row;
  width: 1110px;
  margin-top: 70px;
}

#connect-wallet-view .wrapper {
  min-width: 500px;
  height: 713px;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#connect-wallet-view .wrapper .skey-logo {
  margin-top: 144px;
}

#connect-wallet-data {
  padding-left: 30px;
  width: 100%;
}

#text {
  width: 350px;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: #1b1a17;
  opacity: 0.8;
  margin-bottom: 49px;
  line-height: 1.6;
}

@media(max-width: 1600px) {
  #card {
    box-shadow: none;
  }
  #card.add-photo {
    width: 100%;
    box-sizing: unset !important;
  }
  #card :has(:not(.button-card, .icon, .dropzone-text)) {
    background-color: transparent;
    box-shadow: none;
    /* padding-bottom: 30px; */
    box-sizing: border-box;
  }

  #connect-wallet-view .wrapper {
    max-width: 500px;
    background: transparent;
    width: max-content;
    min-width: 500px;
    box-shadow: none;
    padding-bottom: 30px;
  }

  #qr-with-token-name .token-name {
    display: none;
  }
  
  #qr-with-token-name .nft-address {
    display: block;
  }

  #qr-with-token-name .nft-address > * {
    word-break: break-all;
    line-height: 1.2;
  }

  #qr-with-token-name .nft-address div + div {
    margin-top: 6px;
  }

  #nft-address {
    display: none;
  }
  
  #nft-data {
    align-self: flex-start;
    margin-left: 0px;
    padding: 30px 20px;
  }
  #connect-wallet-view {
    flex-direction: column;
    background-color: white;
    height: max-content;
    flex: unset;
    width: 500px;
    padding: 0px 30px;
    padding-bottom: 30px;
    box-shadow: 6.75273px 5.94984px 11px rgb(0 0 0 / 6%),
    1.35055px 1.18997px 1.7875px rgb(0 0 0 / 3%);
  }

  #connect-wallet-data {
    width: 100%;
    box-sizing: border-box;
    padding-left: unset;
  }

  #progress .progress-bar .line {
    border: 1px solid rgb(229, 229, 229);
    border-left: unset;
    border-right: unset;
  }
  
  #progress .progress-bar .dot {
    border: 1px solid rgb(229, 229, 229);
  }
}
@media (max-width: 1250px) {
  #search-bar {
    margin-bottom: 14px;
    height: 66px;
    width: 100%;
    max-width: 560px;
  }
  #header {
    padding: 0px 30px;
  }

  #qr-with-token-name .nft-address div{
    word-break: break-all;
    width: 100%;
    font-size: 14px;
    letter-spacing: 0.1px;
    font-weight: 600;
    color: #1b1a17;
    line-height: 1.4;
  }

  #nft-actions {
    margin-bottom: 0px;
    padding-top: 27px;
    border-top: 1px solid #DBDADA;
    border-bottom: unset;
    flex-wrap: wrap;
  }

  .nft-action-button {
    margin-bottom: 20px;
  }

  #burn-nft {
    margin-bottom: 20px;
  }

  #owner-info-wrapper {
    border-top: unset;
    flex-direction: column;
    align-items: flex-start;
  }

  #owner-info-wrapper .owner-info {
    margin-bottom: 20px;
  }

  #created-at {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  #search-bar input {
    padding: 12px 53px;
  }
  #search-bar .magnifier {
    position: absolute;
    top: 13px;
    left: 16px;
  }
}

@media (max-width: 950px) {
  #header {
    padding: 0px 30px;
  }

  .new-nft-button {
    display: none;
  }

  #card {
    width: 100%;
    max-width: 560px;
  }

  #card.add-photo {
    width: 100%;
    box-sizing: border-box !important;
  }

  #qr-with-token-name .qr {
    margin-right: 12px;
    padding: 0px;
  }

  #social-media-link  {
    margin-right: 16px;
  }

  #social-media-link span {
    display: none;
  }

  #social-media-link img {
    height: 24px;
    width: 24px;
  }

  #footer {
    height: 36px;
    padding: 0px 30px;
  }

  #burn-nft {
    justify-content: center;
  } 
}

@media (max-width: 600px) {
  #search-bar {
    width: 100% !important;
    margin-right: 0px !important;
    box-sizing: border-box;
  }

  #token-switcher {
    margin-top: 36px;
    width: 100%;
    justify-content: space-evenly;
  }
}

@media(max-width: 450px) {
  #card.add-photo {
    padding-bottom: 0px !important;
  }

  #token-switcher .prev,
  #token-switcher .next {
    padding: 12px 22px;
  }

  #token-switcher {
    justify-content: space-between;
  }
}

@media (max-width: 550px) {
  .transfer-token-input div input {
    font-size: 14px !important;
    transform: translateX(-5px);
  }
}
