#home {
  max-width: 100%;
}

.search-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  height: 60px;
}

.search-title {
  margin-bottom: 17px;
  font-size: 18px;
  letter-spacing: 0.02em;
  font-weight: 600;
}

@media (max-width: 1250px) {
  .search-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 560px;
    margin-bottom: 0px;
    height: max-content;
  }
}

@media(max-width: 600px) {
  .search-wrapper {
    width: 100%;
  }
}
