#main-layout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

#main-layout .top-side {
  flex: 1;
  display: flex;
  flex-direction: row;
}

#main-layout .bottom-side {
  height: 36px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#main-layout .left-side {
  height: 100%;
  width: 350px;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 58px 80px rgba(0, 0, 0, 0.05),
    0px 37.5926px 46.8519px rgba(0, 0, 0, 0.037963),
    0px 22.3407px 25.4815px rgba(0, 0, 0, 0.0303704),
    0px 11.6px 13px rgba(0, 0, 0, 0.025),
    0px 4.72593px 6.51852px rgba(0, 0, 0, 0.0196296),
    0px 1.07407px 3.14815px rgba(0, 0, 0, 0.012037);
  z-index: 2;
}

#main-layout .right-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
}

#main-layout .header-wrapper {
  width: auto;
  height: 98px;
  /* background-color: rgb(122, 122, 122); */
  display: flex;
  align-items: center;
}

#main-layout .content-wrapper {
  flex: 1;
  /* background-color: darkgrey; */
  display: flex;
  flex-direction: column;
  padding: 94px 96px;
  padding-top: 0px;
  height: 100%;
  justify-content: space-between;
}

@media(max-width: 1600px) {
  #main-layout .content-wrapper {
    padding: 0px 30px;
    padding-bottom: 30px;
  }

  #main-layout .right-side {
    width: 100%;
  }
}

@media(max-width: 950px) {
  #main-layout .left-side {
    display: none;
  }
}
